import merge from 'lodash/merge';
const userConfig = require('./config.user.js');

const defaultConfig = {
    DEBUG: {
        SHOW_LANDING: true,
        SLOW_MOTION_PAGE_TRANSITION: false,
    },

    API: {
        BASEPATH: 'https://jpwebsiteapi.kinsta.cloud/wp-json/jpmedia/v1',
        DERIVED_PATH: '/derived',
    },

    GOOGLE_MAPS_API_KEY: 'AIzaSyBRgYxm9Jc3zVgt5xGSRvCQPb3JtqjY8Uw',

    PAGINATION: {
        ITEMS_PER_PAGE: 10,
    },
};

console.log('userConfig:', userConfig);

const Config = merge({}, defaultConfig, {...userConfig});

export default Config;
