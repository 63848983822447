import { create } from 'zustand'
import config from '../config';

export const useImagesStore = create((set) => ({
    images: [],
    setImages: (images) => set((state) => ({ images })),
}))

export const getImages = async () => {
    let images = await fetch(`${config.API.DERIVED_PATH}/images.json`).then(r => r.json())
    return images
}

export async function initImages() {
    let images = await getImages()
    useImagesStore.getState().setImages(images)
}