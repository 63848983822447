import { create } from 'zustand'
import { useRouter as _useRouter } from 'next/router'

export const useRouterStore = create((set) => ({
  state: {},
  //previousPath: null,
  setState: (s) => set((state) => ({ state: s })),
  //setPreviousPath: (previousPath) => set((state) => ({ previousPath})),
}))

export function useRouter() {
  const router = _useRouter()
  const routerStore = useRouterStore()

  const push = (path, params = {}) => {
    //const currentPath = router.asPath
    //routerStore.setPreviousPath(currentPath)
    //console.log("routerStore state: ", useRouterStore.getState())
    routerStore.setState(params)
    router.push(path)
    //console.log(`navigate to ${path} from ${currentPath}`)
  }

  return {
    ...router,
    push
  }
}

export function withRouter(Component) {
    const HOC = (props) => {
        const router = useRouter()
        const routerStore = useRouterStore()
        return <Component router={router} routerStore={routerStore} {...props}/>
    }
    return HOC
}