import { initCaseStudies, useCaseStudiesStore } from './caseStudies';
import { usePagesStore, initPages } from './pages';
import { initNews, useNewsStore } from './news';
import { initImages } from './images';
import { useAppStore } from './app';
import { useEffect, useRef } from 'react';
import { useRouter } from './router';

export function StoreProvider({ children, pageProps }) {
    const pagesStore = usePagesStore()
    const caseStudiesStore = useCaseStudiesStore()
    const newsStore = useNewsStore()
    const appStore = useAppStore()

    const isInit = useRef(false)
    if (!isInit.current) {
        const { page, caseStudy, news } = pageProps || {}

        // set initial data
        if (page) {
            pagesStore.setPages([page])
        }
        if (caseStudy) {
            caseStudiesStore.setCaseStudies([caseStudy])
        }
        if (news) {
            newsStore.setNews([news])
        }

        isInit.current = true
    }

    useEffect(() => {
        // run init functions
        Promise.all([
            initPages(),
            initCaseStudies(),
            initNews(),
            initImages()
        ]).then((values) => {
            console.log('App init complete')
            appStore.setAppLoading(false)
        });
    }, [])

    return children
}