
export const fetchJsonData = async (url) => {
    // https://stackoverflow.com/questions/29246444/fetch-how-do-you-make-a-non-cached-request
    //const ms = Date.now();
    const data = (await (await fetch(`${url}`)).json()).data; //?nocache=${ms}
    return data;
}

//
// merge obj.meta straight into obj to make it easier to deal with the data
//
export const mergeMeta = (obj) => {
    if (obj.meta) {
        for (const [key, value] of Object.entries(obj.meta)) {
            obj[key] = value;
        }
        delete obj.meta;
    }

    // recursive
    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; ++i) {
            //obj[i] =
            mergeMeta(obj[i]);
        }
    } else if (typeof obj === 'object' && obj !== null) {
        // assume object or dictionary
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(obj)) {
            //obj[key] =
            mergeMeta(value);
        }
    }
};

export const serializeURI = (obj) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    return str.join('&');
};