import { useRouter } from '../store/router'
import { useRef } from 'react'

export function NavigationService({ children }) {
    const router = useRouter()
    const isInit = useRef(false)

    if (!isInit.current) {
        const asPath = router.asPath

        // redirect from old urls which use the old hash router to the new url
        if (asPath.startsWith('/#')) {
            const newPath = asPath.replace('/#', '')
            console.log(`Redirecting from ${asPath} to ${newPath}`)
            router.push(newPath)
        }
        isInit.current = true
    }

    return children
}
