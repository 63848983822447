
//import '@/styles/progress-bar.css'
import '../src/scss/common.scss';

import Head from 'next/head'

import { AnalyticsService } from '../src/services/analytics'
import { StoreProvider } from '../src/store/store'
import { getMediaFromPage } from '../src/services/page'
import { NavigationService } from '../src/services/navigation';

export function getPageTitle(title) {
    const cleanTitle = title?.replace('<br/>', ' ').trim()
    const fullTitle = !!cleanTitle ? `JP Media - ${cleanTitle}` : 'JP Media'
    return fullTitle
}

export default function MyApp({ Component, pageProps }) {
    const getLayout = Component.getLayout ?? ((page) => page);

    const { page, caseStudy, news } = pageProps
    const fullTitle = getPageTitle(page?.title || caseStudy?.title || news?.title)
    const pageDescription = page?.excerpt || caseStudy?.shortExcerpt || caseStudy?.excerpt || news?.shortExcerpt || news?.excerpt || 'Everyone has a story.'
    const [images, videos] = getMediaFromPage(page || caseStudy || news)
    
    let ogImage = images?.[0] || null
    if (ogImage && ogImage.startsWith('/')) {
        //ogImage = `${process.env.S3_PATH}${ogImage}`
        ogImage = `https://jpmedia.com.au${ogImage}`
    }

    // console.log(`title: ${fullTitle}, desc: ${pageDescription}, ogImage: ${ogImage}`)

    return (
        <>

            <Head>
                   {/*   causing errors in production?!
                {fullTitle && <title>{fullTitle}</title>}
                {pageDescription && <description key='description'>{pageDescription}</description>}
 */}

                {fullTitle && <meta name="title" property="og:title" content={fullTitle}/>}
                {pageDescription && <meta name="description" property="og:description" content={pageDescription}/>}
                {ogImage && <meta name="image" property="og:image" content={ogImage}/>}

   
                <meta name="twitter:card" content="summary_large_image"/>
                {fullTitle && <meta name="twitter:title" content={fullTitle}/>}
                {pageDescription && <meta name="twitter:description" content={pageDescription}/>}
                {ogImage && <meta name="twitter:image" content={ogImage}/>}

            </Head>
            <AnalyticsService/>
            <NavigationService/>
            <StoreProvider pageProps={pageProps}>
                {getLayout(<Component {...pageProps} />)}
            </StoreProvider>
        </>
    )
}
  