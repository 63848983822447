import config from '../config';
import {mergeMeta, fetchJsonData} from '../utils/fetch';
import {snakeToCamelObj} from '../utils/string';
import { create } from 'zustand'

export const usePagesStore = create((set) => ({
    pages: [],
    setPages: (pages) => set((state) => ({ pages })),
}))

export const transformPages = (pages) => {
    pages = pages.map((t) => {
        mergeMeta(t);
        t = snakeToCamelObj(t);
        return t;
    })
    return pages
}

export const getPages = async () => {
    let pages = await fetchJsonData(`${config.API.DERIVED_PATH}/pages.json`)
    return transformPages(pages)
}

export async function initPages() {
    // now load from s3 incase the user wants to use the rest of the website!
    let pages = await getPages()
    usePagesStore.getState().setPages(pages)
}