import { useEffect } from 'react';
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
//import NProgress from 'nprogress'
import { useRouter } from '../store/router'


export function gaSend(fieldObject) {
    ReactGA.send(fieldObject); // Update the user's current page
}

export function gaEvent(options) {
    ReactGA.event(options)
}

export function AnalyticsService() {
    const router = useRouter()

    useEffect(() => {
        ReactGA.initialize('G-E6WHBGF3MM');

        TagManager.initialize({
            gtmId: 'GTM-KK6NW8N'
        });

        // this current page - ie. on first land
        console.log(`Navigating to '${router.asPath}'`);
        gaSend({hitType: 'pageview', page: router.asPath}); // Update the user's current page
    }, [])

    useEffect(() => {
        const handleStart = () => {
            //NProgress.start()
        }

        const handleStop = () => {
            //NProgress.done()

            console.log(`Navigating to '${router.asPath}'`);
            gaSend({hitType: 'pageview', page: router.asPath}); // Update the user's current page
        }

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleStop);
        router.events.on('routeChangeError', handleStop);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleStop);
            router.events.off('routeChangeError', handleStop);
        };
    }, [router])
    
    return null
}

