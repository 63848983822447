const snakeToCamelStr = (str) => {
    let newStr = str.replace(/([-_].)/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
    //newStr = newStr.charAt(0).toLowerCase() + newStr.slice(1);
    return newStr;
};

// Convert obj and its children recursively from snake_case to camelCase
// https://stackoverflow.com/questions/40710628/how-to-convert-snake-case-to-camelcase-in-my-app
export const snakeToCamelObj = (obj) => {
    if (typeof obj === 'string' || obj instanceof String) {
        return obj;
    }

    let newObj = {};
    for (let d in obj) {
        let value = obj[d];

        // recursive
        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; ++i) {
                value[i] = snakeToCamelObj(value[i]);
            }
        } else if (typeof value === 'object' && value !== null) {
            // assume object or dictionary
            for (const [key, value2] of Object.entries(value)) {
                value[key] = snakeToCamelObj(value2);
            }
        }

        newObj[snakeToCamelStr(d)] = value;
    }

    return newObj;
};
