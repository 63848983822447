export function getMediaFromPage(page) {
    let images = new Set();
    let videos = new Set();

    // pull out a list of stuff we want to pre-cache
    const pageHeaderModule = page?.pageBuilder && page?.pageBuilder.find((module) => module.acfFcLayout === 'page_header_module');
    if (pageHeaderModule) {
        if (pageHeaderModule.backgroundImage) {
            images.add(pageHeaderModule.backgroundImage);
        }
        if (pageHeaderModule.backgroundVideo) {
            videos.add(pageHeaderModule.backgroundVideo);
        }
    }

    return [Array.from(images), Array.from(videos)]
}
